import GetTalentsTabs from "@/app/queries/getTalentsTab";
import bloque from "@/app/styles/Blocks/BloqueTalentsTabs.module.scss";

import TalentTabs from "../../molecules/ButtonTabs/ButtonTabs";
import CardTalent from "../../molecules/cards/CardTalent/CardTalent";
import { BlockProps } from "@/app/types/BlockProps.type";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";

// type ComponentProps = {
// 	id: string;
// };

export const BloqueTalentsTabs = async ({
	id,
	type,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetTalentsTabs, {
		getTalentsTabsId: id,
		fromSite: "video",
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const {
		getChannels: { channels = {} },
		getTalentsTabs: {
			titleBlock = null,
			relationships: { talents = {} } = {},
		} = {},
	} = data ?? {};

	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		colorBloque: undefined,
		content_name: "TALENT-",
		channel: undefined,
		contenidosBloque: talents ? `${talents.length} contenidos` : "",
		countWidget: total,
		descripcionBloque: "TabsDeTalentos",
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: "TabsDeTalentos",
	};
	return (
		<section className={`${bloque["talentsTabs__wrapper"]}`}>
			<div className={`${bloque["talentsTabs__wrapperContent"]}`}>
				<h1 className={`${bloque["talentsTabs__title"]}`}>{titleBlock}</h1>
				<TalentTabs tabData={channels} />
				<div className={`${bloque["talentsTabs__cardsWrapper"]}`}>
					{talents?.map((card: any, i: number) => {
						blockDataLayer.channel =
							card?.relationships?.field_canal?.attributes?.name ?? "N+";
						return (
							<CardTalent
								key={`talent-${card?.id}`}
								talentInfo={card}
								blockData={blockDataLayer}
								cardPosition={i + 1}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);
};
export default BloqueTalentsTabs;
