import { Children, useContext } from "react";
import { NewsInfo } from "../../organisms/BloqueHeroGrid/interfaces/HeroGridResponse";
import { ToggleContext } from "../../../context/ToggleContext";

import heroGrid from "@/app/styles/Blocks/BloqueHeroGrid.module.scss";
import SingleCard from "./SingleCard";
import { BlockDataType } from "@/app/types/BlockDataType";

const Cards = ({
	newsInfo,
	theme,
	noContent,
	loading,
	blockData,
}: {
	newsInfo: NewsInfo[];
	theme: string;
	noContent: string;
	loading: "eager" | "lazy";
	blockData: BlockDataType;
}) => {
	const { isToggle } = useContext(ToggleContext);
	if (!newsInfo) {
		console.warn(`No se recibieron noticias: 'Hero Grid'`);
	}
	const visibleNews =
		newsInfo?.length > 1 ? newsInfo.slice(0, newsInfo.length / 2) : [];
	const invisibleNews =
		newsInfo?.length > 1
			? newsInfo.slice(newsInfo.length / 2, newsInfo.length)
			: [];

	return (
		<>
			{
				<div
					className={`${
						heroGrid[`heroGrid${noContent}__wrapperContentRelations`]
					}`}
				>
					<div
						className={`${heroGrid[`heroGrid${noContent}__wrapperRelations`]}`}
					>
						{Children.toArray(
							visibleNews?.map((news, i) => {
								const typeContent =
									news?.type?.split("--")?.[1] === "news"
										? "NEW"
										: news?.type?.split("--")?.[1]?.toLowerCase() === "liveblog"
										? "liveblog"
										: "VOD";
								blockData.content_name = `${typeContent}-`;
								return (
									<SingleCard
										news={news}
										noContent={noContent}
										theme={theme}
										loading={loading}
										blockData={blockData}
										cardPosition={i + 2}
									/>
								);
							})
						)}
						{Children.toArray(
							invisibleNews?.map((news, i) => {
								return (
									<SingleCard
										news={news}
										noContent={noContent}
										theme={theme}
										visibleClass={`${
											isToggle
												? heroGrid[`heroGrid${noContent}__relations--visible`]
												: heroGrid[`heroGrid${noContent}__relations--hidden`]
										}`}
										loading={loading}
										blockData={blockData}
										cardPosition={i + visibleNews.length + 2}
									/>
								);
							})
						)}
					</div>
				</div>
			}
		</>
	);
};

export default Cards;
