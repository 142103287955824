/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
	useId,
	useCallback,
} from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { print } from "graphql";
import GetLiveBlogEntries from "@/app/queries/getLiveBlogEntries";
import { useSocketEntries } from "@/app/hooks/useLiveBlog";
import useElementOnScreen from "@/app/hooks/useElementOnScreen";
import handleSelectContentECommerce from "@/app/helpers/analytics/handleSelectContentECommerce";
import { BlockProps } from "@/app/types/BlockProps.type";
// import styleSummary from "@/app/styles/organisms/Summary.module.scss";
import styles from "@/app/styles/Blocks/BlockLiveBlog.module.scss";
import Summary from "@/app/components/organisms/Summary/Summary";
import { LBPathUpdater } from "@/app/components/atoms/LBPathUpdater/LBPathUpdater";
import SummarySkeleton from "@/app/components/organisms/Summary/SummarySkeleton";
import If from "../../molecules/If/If";
import handleSelectContent from "@/app/helpers/analytics/handleSelectcontent";
import InfiniteScroll from "../../atoms/InfiniteScroll/InfiniteScroll";
import { EntryLiveBlog } from "@/app/types/EntriesLiveBlog.type";
import LightBox from "@/app/components/molecules/LightBox/LightBox";
import { getPinnedById } from "@/actions";
import SummaryLB from "@/app/components/organisms/Summary/SummaryLB";
import { SocketProvider } from "@/app/context/SocketContext";
const NotificationSnackBar = dynamic(
	() =>
		import("@/app/components/atoms/NotificationSnackBar/NotificationSnackBar")
);
const ButtonSeeMore = dynamic(
	() => import("@/app/components/atoms/Buttons/ButtonSeeMore/ButtonSeeMore")
);

const Icon = dynamic(() => import("@/app/components/atoms/Icon/Icon"));

const PinnedNews = dynamic(
	() => import("@/app/components/molecules/PinnedNews/PinnedNews")
);

export const BlockLiveBlog = ({ id, content, pinnedLiveBlog }: BlockProps) => {
	return (
		<SocketProvider id={id ?? ""} defaultPreview={content?.streamInfo}>
			<Component id={id} content={content} pinnedLiveBlog={pinnedLiveBlog} />
		</SocketProvider>
	);
};

function Component({ id, content, pinnedLiveBlog }: BlockProps) {
	const { summary = {}, isLive, hasSummary } = content ?? {};
	const buttonUniqueId = useId();
	const [buttonId] = useState(buttonUniqueId);
	const path = usePathname();
	const blockDataLayer = useMemo(
		() => ({
			contenidosBloque: "",
			content_name: "",
			content_type: `ActualizacionLiveBlog`,
			count_actualizacion: ``,
			event: "select_content",
			eventAction: "click",
			tipoElemento: "widget",
			tituloBloque: "AperturaLiveBlog",
			countWidget: "0",
			tipoBloque: "",
			clickBloque: undefined,
			colorBloque: undefined,
			contentTitle: undefined,
			descripcionBloque: undefined,
			posicionBloque: undefined,
			section: path,
			subBloque: undefined,
			tamanoBloque: undefined,
		}),
		[]
	);
	const totalItems = 7;
	const [entries, setEntries] = useState<EntryLiveBlog[]>([]);
	const [moreEntries, setMoreEntries] = useState<EntryLiveBlog[]>([]);
	const [pinnedEntry, setPinnedEnty] = useState<EntryLiveBlog>();
	const [sharedEntry, setSharedEnty] = useState<any>();
	const [lastUpdate, setLastUpdate] = useState<string>("loading");
	const [rotated, setRotated] = useState(false);
	const [indexOfElements, setIndexOfElements] = useState(0);
	const [reloadLiveblog, setReloadLiveblog] =
		useState<string>("Recargar Liveblog");
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isScrollLoading, setIsScrollLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(totalItems);
	const [hasMore, setHasMore] = useState(true);
	const [entriesCount, setEntriesCount] = useState(0);
	const [refreshCount, setRefreshCount] = useState(0);
	const [canLoadMore, setCanLoadMore] = useState(true);
	const [isligthBoxOpen, setIsLigthBoxOpen] = useState(false);
	const toIds: any = [];
	const [newsDataLayer, setNewsDataLayer] = useState([]);
	const eCommerceDataLayer = {
		item_brand: "",
		item_category: undefined,
		item_category3: undefined,
		item_category2: "",
		item_variant: "",
		item_id: "",
		quantity: 0,
		item_name: "",
		item_list_id: "",
		item_list_name: "",
	};

	const getEntries = useCallback(async () => {
		const lbHashId = localStorage.getItem("lbHashId");
		const sharedLiveblog = getPinnedById(lbHashId || "", "shared");
		const reqheaders = new Headers();
		reqheaders.append("Content-Type", "application/json");
		reqheaders.append(
			"X-Secure-Environment",
			`${process.env.NEXT_PUBLIC_APOLLOSEC}`
		);
		const graphReq = JSON.stringify({
			query: print(GetLiveBlogEntries),
			variables: {
				getLiveBlogEntriesId: id,
				totalItems,
				page: 0,
			},
		});
		const reqOpts = {
			method: "POST",
			headers: reqheaders,
			body: graphReq,
			next: { revalidate: 5 },
		};
		const endpoint = process.env.NEXT_PUBLIC_APOLLO_SERVER_URL ?? "";

		fetch(endpoint, reqOpts)
			.then((res) => {
				if (res?.status === 200) return res?.json();
				return null;
			})
			.then((data) => {
				const resp = data?.data?.getLiveBlogEntries;
				if (resp && resp.length > 0) {
					setNewsDataLayer(resp);
					// Ordena las entradas por fecha de publicación de manera descendente usando .getTime()
					const sortedEntries = resp.sort(
						(a: any, b: any) =>
							new Date(b.datePublished).getTime() -
							new Date(a.datePublished).getTime()
					);
					setEntries(resp);

					const to1 = setTimeout(() => {
						setIsLoading(false);
					}, 2000);
					toIds.push(to1);
					// Establece la última fecha de actualización basada en la entrada más reciente
					setLastUpdate(sortedEntries[0]?.datePublished);
					setEntriesCount(resp?.[0]?.meta);
				} else {
					setLastUpdate(
						content?.datePublished ?? content?.dateModified ?? "loading"
					);
					const to2 = setTimeout(() => {
						setIsLoading(false);
					}, 2000);
					toIds.push(to2);
				}
				setReloadLiveblog("Recargar Liveblog");
				setRefreshCount((prevCount) => prevCount + 1);
				const to3 = setTimeout(() => {
					setRotated(false);
				}, 1500);
				toIds.push(to3);
			})
			.catch((err) => {
				setReloadLiveblog("Recargar Liveblog");
				const to4 = setTimeout(() => {
					setRotated(false);
				}, 1000);
				toIds.push(to4);
				setLastUpdate(
					content?.datePublished ?? content?.dateModified ?? "loading"
				);
				const to5 = setTimeout(() => {
					setIsLoading(false);
				}, 5000);
				toIds.push(to5);
				return {
					data: null,
					error: err,
				};
			});
		const pinned = await pinnedLiveBlog;
		const shared = await sharedLiveblog;
		shared && setIsLigthBoxOpen(true);
		setSharedEnty(shared);
		setPinnedEnty(pinned);
	}, []);

	const getMoreEntries = async () => {
		// * Set the continue index in the elemenets
		const arrIndex = Array.from(
			{ length: newsDataLayer?.length || 0 },
			(_, index) => index + indexOfElements
		);
		setIndexOfElements(indexOfElements + newsDataLayer?.length || 0);

		const newsObject = newsDataLayer?.map((news: any, index) => {
			const cleanTitle = news?.title?.replaceAll('"', "")?.trim();

			return {
				...eCommerceDataLayer,
				index: arrIndex?.[index],
				item_brand: "",
				item_category2: path || "",
				item_variant: news?.ctNode?.[0]?.type
					? news?.ctNode?.[0]?.type
					: news?.steamingInfo?.pathLive
					? "en vivo"
					: "text",
				item_name: cleanTitle,
				item_id: news?.eid || news?.id,
				item_list_id: "live_blog",
				item_list_name: "liveBlog",
				quantity: arrIndex?.[index],
			};
		});
		handleSelectContentECommerce(
			newsObject,
			"live_blog",
			"liveBlog",
			"view_item_list"
		);
		setNewsDataLayer([]);

		setCurrentPage((prevPage) => prevPage + 7);
		setIsScrollLoading(true);
		const reqheaders = new Headers();
		reqheaders.append("Content-Type", "application/json");
		reqheaders.append(
			"X-Secure-Environment",
			`${process.env.NEXT_PUBLIC_APOLLOSEC}`
		);
		const graphReq = JSON.stringify({
			query: print(GetLiveBlogEntries),
			variables: {
				getLiveBlogEntriesId: id,
				totalItems,
				page: currentPage, // Utiliza el valor actualizado de currentPage
			},
		});
		const reqOpts = {
			method: "POST",
			headers: reqheaders,
			body: graphReq,
			next: { revalidate: 5 },
		};
		const endpoint = process.env.NEXT_PUBLIC_APOLLO_SERVER_URL ?? "";

		fetch(endpoint, reqOpts)
			.then((res) => {
				if (res?.status === 200) return res?.json();
				return null;
			})
			.then((data) => {
				const resp = data?.data?.getLiveBlogEntries;

				if (resp && resp.length > 0) {
					setNewsDataLayer(resp);
					// Ordena las entradas por fecha de publicación de manera descendente usando .getTime()
					const sortedEntries = resp.sort(
						(a: any, b: any) =>
							new Date(b.datePublished).getTime() -
							new Date(a.datePublished).getTime()
					);
					// Establece la última fecha de actualización basada en la entrada más reciente
					setMoreEntries((prevEntries) => [...prevEntries, ...resp]);
					setIsScrollLoading(false);
				} else {
					setHasMore(false);
					setIsScrollLoading(false);
				}
			})
			.catch((err) => {
				setHasMore(false);
				setIsScrollLoading(false);
				return {
					data: null,
					error: err,
				};
			});
	};

	const handleLoadMore = async () => {
		if (!canLoadMore) return;
		setCanLoadMore(false);

		await getMoreEntries();

		setTimeout(() => {
			setCanLoadMore(true);
		}, 7000);
	};

	useEffect(() => {
		getEntries();

		const lbHashId = localStorage.getItem("lbHashId");
		const timeOutId: ReturnType<typeof setTimeout> = setTimeout(() => {
			if (!lbHashId) {
				return;
			}
			document.location.href = `#${lbHashId}`;
		}, 4000);
		localStorage.setItem("lbHashId", "");

		return () => {
			clearTimeout(timeOutId);
			for(const to of toIds){
				clearTimeout(to);
			}
		};
	}, []);

	useEffect(() => {
		if (!entries.length && isLoading) return;

		const lbHashId = localStorage.getItem("lbHashId");
		const url = new URL(document.location.href);
		if (lbHashId) {
			url.searchParams.set("entry", lbHashId ?? "");
			window.history.replaceState(window.history.state, "", url);
		}
	}, [entries, isLoading]);

	return (
		<section className={styles?.mainSection}>
			<div
				className={`${styles.reloadSection} ${rotated ? styles.rotated : ""}`}
			>
				<div
					onClick={() => {
						setReloadLiveblog("Recargando Liveblog");
						setRotated(true);
						getEntries();
					}}
				>
					<Icon name="reload" />
					<span>{reloadLiveblog}</span>
				</div>
			</div>
			{isligthBoxOpen && sharedEntry && (
				<LightBox
					isModal
					theme="light"
					onClose={() => setIsLigthBoxOpen(false)}
				>
					<div className={`${styles.liveBlogModal}`}>
						<button
							className={`${styles.closeButton}`}
							type="button"
							onClick={() => {
								localStorage.removeItem("lbHashId");
								setIsLigthBoxOpen(false);
							}}
						>
							<Icon name="close" />
						</button>
						<Summary
							content={sharedEntry}
							shouldUpdateByNote={rotated}
							dataLayer={{
								...blockDataLayer,
								content_type: `LiveBlog-${1}`,
								content_name: `${sharedEntry?.title}`,
								index: 1,
								buttonId,
								currentPath: path,
							}}
							isLiveBlogLayer={true}
							hasShare={false}
							isModal
						/>
					</div>
				</LightBox>
			)}
			<SummaryLB
				main
				id={id}
				versionTag={isLive ? "" : "gray"}
				lastUpdate={lastUpdate}
				content={content}
				shouldUpdateByNote={true}
				idEntry={id?.toString()}
				dataLayer={{
					...blockDataLayer,
					content_type: `LiveBlog-${0}`,
					content_name: `${content?.title}`,
					index: 0,
					buttonId,
					currentPath: path,
					entry: content,
				}}
			/>
			<section className={styles?.liveBlogBody}>
				<SummarySkeleton isLoading={isLoading} quantity={3} />
				{!isLoading && hasSummary && <PinnedNews accordeon summary={summary} />}

				{!isLoading && pinnedEntry && !hasSummary && (
					<Summary
						content={pinnedEntry}
						shouldUpdateByNote={true}
						dataLayer={{
							...blockDataLayer,
							content_type: `LiveBlog-${1}`,
							content_name: `${pinnedEntry?.title}`,
							index: 1,
							buttonId,
							currentPath: path,
							pinnedEntry,
						}}
						isLiveBlogLayer={true}
						pinned={true}
					/>
				)}
				<If condition={isLive}>
					<SubscriptionEntries
						id={id}
						blockDataLayer={blockDataLayer}
						updater={entries?.length}
					/>
				</If>
				{!isLoading &&
					entries?.map((entry: any, index: number) => {
						const cleanTitle =
							entry?.title ||
							entry?.ctNode[0]?.title?.replaceAll('"', "")?.trim();
						return (
							<React.Fragment key={index}>
								<Summary
									key={refreshCount}
									content={entry}
									shouldUpdateByNote={rotated}
									dataLayer={{
										...blockDataLayer,
										content_type: `LiveBlog-${
											pinnedEntry ? index + 2 : index + 1
										}`,
										content_name: `${cleanTitle}`,
										index: pinnedEntry ? index + 2 : index + 1,
										buttonId,
										currentPath: path,
										entry,
									}}
									isLiveBlogLayer={true}
								/>
								{index === entries.length - 1 && <LBPathUpdater />}
							</React.Fragment>
						);
					})}
				<If condition={entries.length > 0 && !isLoading}>
					<InfiniteScroll
						loadMore={handleLoadMore}
						hasMore={hasMore}
						isLoading={isScrollLoading}
					>
						{moreEntries?.map((entry: any, index: number) => {
							return (
								<Summary
									key={index}
									content={entry}
									shouldUpdateByNote={rotated}
									dataLayer={{
										...blockDataLayer,
										content_type: `LiveBlog-${
											pinnedEntry ? index + 2 : index + 1
										}`,
										content_name: `${entry?.title}`,
										index: pinnedEntry ? index + 2 : index + 1,
										buttonId,
										currentPath: path,
										entry,
									}}
									isLiveBlogLayer={true}
								/>
							);
						})}
					</InfiniteScroll>
				</If>
				{(!isLoading && pinnedEntry !== undefined) || entries?.length > 0 ? (
					<ButtonSeeMore
						textBtn={"Volver al Inicio"}
						iconPosition="end"
						iconName="arrowTop"
						type="dark"
						onClick={() => {
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							});
							handleSelectContent(
								{
									...blockDataLayer,
									content_type: "Volver al inicio",
									content_name: content?.title,
									section: path,
									countWidget: undefined,
									tipoElemento: undefined,
									tituloBloque: undefined,
									tipoBloque: undefined,
									contenidosBloque: undefined,
								},
								undefined,
								path || ""
							);
						}}
					/>
				) : null}
			</section>
		</section>
	);
}

function SubscriptionEntries({ id, blockDataLayer, updater }: any) {
	const scrollObserverRef = useRef<any>(null);
	const path = usePathname();
	const buttonUniqueId = useId();
	const [buttonId] = useState(buttonUniqueId);
	// TODO: hooks que se conecta al socket de apollo y se le envia el id de la nota a suscribir
	// Se trae un contador de actualizaciones el objeto con la data de la actualización
	// funcion para reiniciar el contador
	const { counter, updateEntries, entries, handlePrewiewUpdates } =
		useSocketEntries({
			id,
		});

	// Reinicia el estado cuando refrescan desde el botón superior

	// hook para saber si la etiqueta es visible
	// shouldActive es un booleano que se le pasa al hook para saber si se activa o no, en este caso, se activa cuando el contador es diferente de 0
	// que es cuando hay una entrada nueva del WebSocket, para evitar estar todo el tiempo renders por activacion del observer.
	const { isVisible } = useElementOnScreen(
		scrollObserverRef,
		{
			root: null,
			rootMargin: "0px",
			threshold: 0.2,
		},
		counter !== 0
	);
	const scrollToUpdates = () => {
		handleSelectContentECommerce(
			[
				{
					index: updateEntries.length,
					item_brand: "Boton Nueva actualizacion",
					item_category: undefined,
					item_category2: path || "",
					item_category3: undefined,
					item_id: `${buttonId}`,
					item_name: "Nueva Actualizacion",
					item_variant: "botón",
					quantity: updateEntries?.length,
					item_list_id: "live_blog",
					item_list_name: "liveBlog",
				},
			],
			"live_blog",
			"liveBlog",
			"select_item"
		);
		if (updateEntries?.length > 0) {
			// Realizar scroll hacia el elemento ScrollObserver
			const scrollObserverPos =
				scrollObserverRef.current.getBoundingClientRect().top;

			// Realizar scroll hacia el elemento ScrollObserver con un offset de 56 píxeles
			if (scrollObserverRef.current) {
				window.scrollTo({
					top: window.scrollY + scrollObserverPos - 100,
					behavior: "smooth",
				});
			}
			handlePrewiewUpdates();
		}
	};

	return (
		<>
			<div className={styles?.divisor} ref={scrollObserverRef} />
			{entries?.map((entry: any, index: number) => {
				return (
					<React.Fragment key={entry.eid}>
						<Summary
							content={entry}
							shouldUpdateByNote={isVisible || counter !== 0}
							dataLayer={{
								...blockDataLayer,
								content_type: `LiveBlog-${index + 1}`,
								content_name: `${entry?.title}`,
								index: index + 1,
								buttonId,
								currentPath: path,
								entry,
							}}
							isLiveBlogLayer={true}
						/>
					</React.Fragment>
				);
			})}
			<NotificationSnackBar
				counter={counter}
				outVisibility={isVisible}
				onClick={() => scrollToUpdates()}
				dataLayerInfo={{
					path: path || "",
					buttonId,
					entries,
				}}
			/>
		</>
	);
}

export default BlockLiveBlog;
